import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-grand-prairie-texas.png'
import image0 from "../../images/cities/scale-model-of-oil-derrick-in-grand-prairie-texas.png"
import image1 from "../../images/cities/scale-model-of-arlington-museum-of-art-in-grand-prairie-texas.png"
import image2 from "../../images/cities/scale-model-of-six-flags-over-texas-in-grand-prairie-texas.png"
import image3 from "../../images/cities/scale-model-of-at&t-stadium-in-grand-prairie-texas.png"
import image4 from "../../images/cities/scale-model-of-six-flags-hurricane-harbor-in-grand-prairie-texas.png"
import image5 from "../../images/cities/scale-model-of-cedar-ridge-preserve-in-grand-prairie-texas.png"
import image6 from "../../images/cities/scale-model-of-knapp-heritage-park-in-grand-prairie-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Grand Prairie'
            state='Texas'
            image={image}
            lat='32.7459645'
            lon='-96.99778459999999'
            attractions={ [{"name": "Oil Derrick", "vicinity": "2148-, 2510 E Copeland Rd, Arlington", "types": ["point_of_interest", "establishment"], "lat": 32.7580457, "lng": -97.0711331}, {"name": "Arlington Museum of Art", "vicinity": "201 W Main St, Arlington", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.736921, "lng": -97.10856469999999}, {"name": "Six Flags Over Texas", "vicinity": "2201 E Road to Six Flags St, Arlington", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 32.7551732, "lng": -97.07117140000003}, {"name": "AT&T Stadium", "vicinity": "1 AT&T Way, Arlington", "types": ["stadium", "point_of_interest", "establishment"], "lat": 32.7472844, "lng": -97.09449389999997}, {"name": "Six Flags Hurricane Harbor", "vicinity": "1800 E Lamar Blvd, Arlington", "types": ["amusement_park", "point_of_interest", "establishment"], "lat": 32.7619126, "lng": -97.0828909}, {"name": "Cedar Ridge Preserve", "vicinity": "7171 Mountain Creek Pkwy, Dallas", "types": ["park", "point_of_interest", "establishment"], "lat": 32.6383012, "lng": -96.95488879999999}, {"name": "Knapp Heritage Park", "vicinity": "201 W Front St, Arlington", "types": ["museum", "point_of_interest", "establishment"], "lat": 32.7380176, "lng": -97.10860100000002}] }
            attractionImages={ {"Oil Derrick":image0,"Arlington Museum of Art":image1,"Six Flags Over Texas":image2,"AT&T Stadium":image3,"Six Flags Hurricane Harbor":image4,"Cedar Ridge Preserve":image5,"Knapp Heritage Park":image6,} }
       />);
  }
}